<template>
  <footer class="footer bg-white text-dark">
    <div class="container">
      <div class="row">
        <!-- Brand Section -->
        <div class="col-md-3 mb-4 mb-md-0">
          <div class="footer-brand">
            <a href="/" class="footer-brand-name">Susan M. Ryan, DPM</a>
          </div>
          <p class="footer-text mt-2">Caring for Every Step You Take.</p>
        </div>
        <!-- Contact Section -->
        <div class="col-md-5 mb-4 mb-md-3">
          <h5 class="footer-heading footer-heading-large">Contact Us</h5>
          <ul class="footer-contact footer-contact-large">
            <li>
              <i class="bi bi-telephone"></i>
              <a href="tel:+15102090506" class="footer-link"> +15102090506 </a>
            </li>
            <li>
              <i class="bi bi-envelope"></i>
              <a
                href="mailto:
ryansm2013@yahoo.com"
                class="footer-link"
              >
                ryansm2013@yahoo.com
              </a>
            </li>
            <li>
              <i class="bi bi-geo-alt footer-address-icon"></i>
              <address class="footer-address">
                6000 Fairway Drive<br />
                Suite 1<br />
                Rocklin, CA 95677
              </address>
            </li>
          </ul>
        </div>
        <!-- Office Hours Section -->
        <div class="col-md-4">
          <h5 class="footer-heading footer-heading-small">Office Hours</h5>
          <p class="footer-text footer-text-small">
            Monday through Friday 10:00 am to 4:00 pm
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterS",
};
</script>

<style scoped>
.footer {
  padding: 20px 0;
  background-color: #ffffff;
  border-top: 1px solid #e0e0e0;
}

.footer-brand {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.footer-logo {
  font-size: 2rem; /* Размер иконки рядом с логотипом */
  margin-right: 10px; /* Расстояние между иконкой и текстом */
}

.footer-brand-name {
  font-size: 1.25rem;
  font-weight: bold;
  color: #212529;
  text-decoration: none;
}

.footer-text {
  color: #6c757d;
  font-size: 0.875rem;
}

.footer-heading {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #212529;
}

.footer-heading-large {
  font-size: 1.5rem;
}

.footer-heading-small {
  font-size: 1rem;
}

.footer-contact,
.footer-nav {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-contact li,
.footer-nav li {
  margin-bottom: 12px;
  display: flex;
  align-items: flex-start;
}

.footer-link {
  color: #212529;
  text-decoration: none;
  margin-left: 8px;
}

.footer-link:hover {
  color: #007bff;
}

.footer-address-container {
  display: flex;
  align-items: flex-start;
}

.footer-address-icon {
  margin-right: 8px;
}

.footer-address {
  font-style: normal;
}

.footer-contact-large {
  font-size: 1rem;
}

.footer-text-small {
  font-size: 0.75rem;
}

@media (max-width: 768px) {
  .footer-heading {
    font-size: 1.125rem;
  }

  .footer-logo {
    font-size: 1.5rem;
  }
}
</style>
