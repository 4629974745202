<template>
  <div class="hero-section" id="home">
    <div class="hero-content">
      <div class="hero-text">
        <h1
          class="hero-title wow animate__animated animate__fadeInDown"
          data-wow-delay="0.1s"
        >
          Dedicated to Your Foot Health
        </h1>
        <h2
          class="hero-subtitle wow animate__animated animate__fadeInDown"
          data-wow-delay="0.3s"
        >
          Susan M. Ryan, DPM
        </h2>
        <p
          class="highlight-text wow animate__animated animate__fadeInDown"
          data-wow-delay="0.6s"
        >
          Specializing in Mobile podiatry. Let us come to you!
        </p>
        <p
          class="hero-description wow animate__animated animate__fadeInDown"
          data-wow-delay="0.5s"
        >
          At the office of Dr. Susan M. Ryan, we are dedicated to providing
          exceptional podiatric care focused on improving mobility, enhancing
          the quality of life for all our patients through expert and
          compassionate care, and offering specialized mobile wound care to
          ensure that treatment is delivered conveniently in the comfort of
          their own homes.
        </p>
        <a
          href="#about"
          class="hero-button wow animate__animated animate__fadeInUp"
          data-wow-delay="0.7s"
        >
          Learn More
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { WOW } from "wowjs";
import "animate.css";

export default {
  name: "HeroSection",
  mounted() {
    new WOW().init();
  },
};
</script>

<style lang="scss" scoped>
.hero-section {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(to right, #0c3c5d, #004080);
  padding: 40px 20px;
  text-align: center;
  color: #ffffff;

  .hero-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    width: 100%;
  }

  .hero-text {
    max-width: 600px;
    margin-bottom: 40px;

    .hero-title {
      font-size: 2.5rem;
      margin-bottom: 20px;

      @media (max-width: 768px) {
        font-size: 2rem;
      }

      @media (max-width: 576px) {
        font-size: 1.75rem;
      }
    }

    .hero-subtitle {
      font-size: 1.5rem;
      margin-bottom: 20px;
      color: #e0e0e0;

      @media (max-width: 768px) {
        font-size: 1.25rem;
      }

      @media (max-width: 576px) {
        font-size: 1rem;
      }
    }

    .hero-description {
      font-size: 1rem;
      margin-bottom: 30px;
      line-height: 1.5;
      color: #d0d0d0;

      @media (max-width: 768px) {
        font-size: 0.875rem;
      }
    }

    .highlight-text {
      font-size: 1.5rem; /* Adjust size as needed */
      font-weight: bold;
      color: #ffffff; /* Adjust color as needed */
      margin-top: 20px; /* Space above the text */
      text-align: center; /* Center align text */
    }

    .hero-button {
      padding: 12px 30px;
      font-size: 1rem;
      color: #0c3c5d;
      background-color: #ffffff;
      border-radius: 25px;
      text-transform: uppercase;
      letter-spacing: 1px;
      transition: background-color 0.3s ease;
      text-decoration: none;

      &:hover {
        background-color: #e0e0e0;
      }
    }
  }

  .hero-image {
    width: 100%;
    max-width: 500px;

    .doctor-image {
      width: 100%;
      border-radius: 10px;
      box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    }
  }

  @media (max-width: 768px) {
    .hero-content {
      flex-direction: column;
    }
  }

  @media (max-width: 576px) {
    .hero-section {
      padding: 20px 10px;
    }
  }
}
</style>
