<template>
  <div class="affiliations-credentials">
    <div class="container py-5">
      <Care />

      <!-- Affiliations Section -->
      <div class="section">
        <h2>Affiliations</h2>
        <div class="flex-container">
          <!-- Hospital Affiliations -->
          <div class="affiliations">
            <h3>Hospital Affiliations</h3>
            <ul>
              <li>Sutter Auburn Faith Hospital</li>
              <li>Sutter Roseville Medical Center</li>
            </ul>
          </div>

          <!-- Medical Affiliations -->
          <div class="affiliations">
            <h3>Medical Affiliations</h3>
            <ul>
              <li>Sutter Independent Physicians</li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Accepted Health Plans Section -->
      <div class="section">
        <h2>Accepted Health Plans</h2>
        <p>
          Below is a list of plans accepted by this doctor. While we strive to
          keep this list up to date, it's always a good idea to check with your
          health plan to determine the specific details of your coverage, and to
          contact the doctor's office to verify acceptance of your particular
          plan.
        </p>
      </div>

      <!-- Credentials & Notables Section -->
      <div class="section">
        <h2>Credentials & Notables</h2>
        <div class="flex-container">
          <!-- Medical School -->
          <div class="credentials">
            <h3>Medical School:</h3>
            <p>Samuel Merritt College of Podiatric Medicine</p>
          </div>

          <!-- Residency -->
          <div class="credentials">
            <h3>Residency:</h3>
            <p>St. Mary's Hospital and Medical Center</p>
          </div>

          <!-- Special Training -->
          <div class="credentials">
            <h3>Special Training:</h3>
            <p>ABMSP Certified in Podiatric Medicine</p>
          </div>
        </div>
      </div>

      <!-- Languages Section -->
      <div class="section">
        <h2>Languages</h2>
        <div class="flex-container">
          <!-- Primary Language -->
          <div class="languages">
            <h3>Primary Language(s)</h3>
            <p>English</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Care from "@/components/Care.vue";

export default {
  name: "AffiliationsAndCredentials",
  components: {
    Care,
  },
};
</script>

<style lang="scss" scoped>
.affiliations-credentials {
  padding: 3rem 0;
  background-color: #f8f9fa;

  .section {
    margin-bottom: 3rem;

    h2 {
      color: #343a40;
      font-size: 2rem;
      font-weight: 700;
      border-bottom: 2px solid #0c3c5d;
      padding-bottom: 0.5rem;
      margin-bottom: 1.5rem;

      @media (max-width: 768px) {
        font-size: 1.75rem;
      }

      @media (max-width: 576px) {
        font-size: 1.5rem;
      }
    }

    h3 {
      color: #0c3c5d;
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 1rem;

      @media (max-width: 768px) {
        font-size: 1.25rem;
      }

      @media (max-width: 576px) {
        font-size: 1rem;
      }
    }

    p {
      color: #495057;
      font-size: 1.125rem;
      line-height: 1.6;
      margin-bottom: 1rem;

      @media (max-width: 576px) {
        font-size: 1rem;
      }
    }

    ul {
      list-style: disc;
      padding-left: 1.5rem;
      margin: 0;

      li {
        margin-bottom: 0.5rem;
        font-size: 1.125rem;

        @media (max-width: 576px) {
          font-size: 1rem;
        }
      }
    }
  }

  .flex-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .affiliations,
  .credentials,
  .languages {
    flex: 1 1 calc(50% - 1.5rem);
    border: 1px solid #e0e0e0;
    border-radius: 0.5rem;
    padding: 1.5rem;
    background-color: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
      flex: 1 1 100%;
    }
  }

  .languages {
    flex: 1 1 100%;
  }
}
</style>
