<template>
  <div class="main-section" id="s">
    <div class="container py-5">
      <!-- Intro Text -->
      <div
        class="text-center mb-5 wow animate__animated animate__fadeInUp animate__fast"
      >
        <h2>Accepted Health Plans</h2>
        <p>
          I, Dr. Susan M. Ryan, accept a range of health plans to ensure you
          receive the care you need. While I strive to keep this list current, I
          recommend verifying the details of your plan with your provider and
          contacting my office to confirm acceptance of your specific plan.
        </p>
      </div>

      <!-- Health Plans List -->
      <div class="row gy-4">
        <div
          class="col-12 wow animate__animated animate__fadeInUp animate__fast"
        >
          <div class="info-box">
            <h3>Aetna</h3>
            <ul class="service-list">
              <li>Health Fund HMO</li>
              <li>HMO</li>
              <li>HMO Deductible Plans</li>
              <li>Quality Point of Service (QPOS)</li>
            </ul>
          </div>
        </div>

        <div
          class="col-12 wow animate__animated animate__fadeInUp animate__fast"
        >
          <div class="info-box">
            <h3>Alignment</h3>
            <ul class="service-list">
              <li>Alignment Health Plan My Choice (Medicare Advantage PPO)</li>
              <li>
                Alignment Health Plan Sutter Advantage (Medicare Advantage HMO)
              </li>
              <li>Retiree Options (Medicare Advantage HMO)</li>
              <li>Retiree Options (Medicare Advantage PPO)</li>
            </ul>
          </div>
        </div>

        <div
          class="col-12 wow animate__animated animate__fadeInUp animate__fast"
        >
          <div class="info-box">
            <h3>Anthem Blue Cross of California</h3>
            <p class="note">In Negotiation for 2025 Contracts</p>
            <ul class="service-list">
              <li>Anthem Blue Cross HMO (including CalPERS Traditional HMO)</li>
              <li>Anthem Blue Cross HMO/POS (California Care Plus)</li>
            </ul>
          </div>
        </div>

        <div
          class="col-12 wow animate__animated animate__fadeInUp animate__fast"
        >
          <div class="info-box">
            <h3>Blue Shield of California</h3>
            <ul class="service-list">
              <li>Blue Shield Access+ HMO Plan</li>
            </ul>
          </div>
        </div>

        <div
          class="col-12 wow animate__animated animate__fadeInUp animate__fast"
        >
          <div class="info-box">
            <h3>Cigna</h3>
            <ul class="service-list">
              <li>Commercial HMO/POS</li>
              <li>Network HMO/POS</li>
            </ul>
          </div>
        </div>

        <div
          class="col-12 wow animate__animated animate__fadeInUp animate__fast"
        >
          <div class="info-box">
            <h3>Health Net</h3>
            <ul class="service-list">
              <li>
                Health Net HMO: Large Group (101+ employees) & Small Group
                (2-100 employees)
              </li>
              <li>Salud HMO Plus Large/Custom Group</li>
              <li>
                Seniority Plus (Employer HMO) - Medicare Advantage for all
                Employer-Sponsored plans
              </li>
              <li>WellCare Premium Ultra HMO</li>
            </ul>
          </div>
        </div>

        <div
          class="col-12 wow animate__animated animate__fadeInUp animate__fast"
        >
          <div class="info-box">
            <h3>Sutter Health Plus</h3>
            <ul class="service-list">
              <li>Individual and Family Plan HMO</li>
              <li>Large and Small Group HMO</li>
            </ul>
          </div>
        </div>

        <div
          class="col-12 wow animate__animated animate__fadeInUp animate__fast"
        >
          <div class="info-box">
            <h3>UnitedHealthcare</h3>
            <ul class="service-list">
              <li>
                AARP Healthcare Options Medigap Policy (Supplement Program)
              </li>
              <li>AARP Medicare Advantage from UHC CA-0008 (HMO-POS)</li>
              <li>AARP Medicare Advantage from UHC CA-0009 (HMO-POS)</li>
              <li>AARP Medicare Advantage from UHC CA-002P (HMO-POS)</li>
              <li>AARP Medicare Secure Horizon (HMO) Employer Group</li>
              <li>Signature Value (HMO)</li>
              <li>
                Signature Value Alliance HMO (including CalPERS & AON Exchange)
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WOW } from "wowjs";
import "animate.css";

export default {
  name: "HealthPlans",
  mounted() {
    new WOW().init();
  },
};
</script>

<style lang="scss" scoped>
.main-section {
  padding: 60px 20px;

  h2 {
    color: #000;
    font-size: 2rem;
    margin-bottom: 20px;

    @media (max-width: 576px) {
      font-size: 1.75rem;
    }
  }

  p {
    color: #141414;
    font-size: 1.125rem;
    margin-bottom: 40px;

    @media (max-width: 576px) {
      font-size: 1rem;
    }
  }

  .info-box {
    background-color: #ffffff;
    border-left: 5px solid #0c3c5d; /* Accent Green Line */
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);

    h3 {
      color: #0c3c5d;
      font-size: 1.5rem;
      margin-bottom: 15px;

      @media (max-width: 576px) {
        font-size: 1.25rem;
      }
    }

    p.note {
      color: #dc3545; /* Red for Notes */
      font-weight: bold;
      margin-bottom: 15px;
    }
  }

  .service-list {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      border-bottom: 1px solid #e0e0e0;
      padding: 10px 0;
      color: #495057;
    }
  }

  .animate__fast {
    animation-duration: 0.5s !important;
  }
}
</style>
