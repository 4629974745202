<template>
  <Main />
  <div class="home container wow animate__animated animate__fadeIn">
    <PatientStories />
  </div>
  <AffiliationsAndCredentials />
  <About />
  <Plans class="container"> </Plans>
  <Contacts />
</template>

<script>
import { WOW } from "wowjs";
import "animate.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js"; // Ensure Bootstrap's JavaScript is imported
import Main from "@/components/Main.vue";
import Plans from "@/components/Plans.vue";
import Contacts from "@/components/Contacts.vue";
import PatientStories from "@/components/PatientStories.vue";
import AffiliationsAndCredentials from "@/components/About.vue";

export default {
  name: "HomeView",
  components: {
    Main,
    Plans,
    Contacts,
    PatientStories,
    AffiliationsAndCredentials,
  },
  mounted() {
    new WOW().init();
  },
};
</script>
