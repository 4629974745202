<template>
  <div class="conditions-treated">
    <div class="container">
      <!-- Conditions Treated Section -->
      <div class="section">
        <h2>Conditions Treated</h2>
        <div class="flex-container">
          <!-- Wound Care -->
          <div class="condition">
            <h3>Wound Care</h3>
            <ul>
              <li>Chronic wounds</li>
              <li>Diabetic foot ulcers</li>
              <li>Pressure sores</li>
              <li>Venous stasis ulcers</li>
            </ul>
          </div>

          <!-- Foot and Ankle -->
          <div class="condition">
            <h3>Foot and Ankle</h3>
            <ul>
              <li>Plantar fasciitis</li>
              <li>Achilles tendonitis</li>
              <li>Heel spurs</li>
              <li>Ankle sprains</li>
            </ul>
          </div>

          <!-- Skin Conditions -->
          <div class="condition">
            <h3>Skin Conditions</h3>
            <ul>
              <li>Corns and calluses</li>
              <li>Warts</li>
              <li>Fungal infections</li>
              <li>Dermatitis</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConditionsTreated",
};
</script>

<style lang="scss" scoped>
.conditions-treated {
  background-color: #f8f9fa;

  .section {
    margin-bottom: 3rem;

    h2 {
      color: #343a40;
      font-size: 2rem;
      font-weight: 700;
      border-bottom: 2px solid #0c3c5d;
      padding-bottom: 0.5rem;
      margin-bottom: 1.5rem;

      @media (max-width: 768px) {
        font-size: 1.75rem;
      }

      @media (max-width: 576px) {
        font-size: 1.5rem;
      }
    }

    h3 {
      color: #0c3c5d;
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 1rem;

      @media (max-width: 768px) {
        font-size: 1.25rem;
      }

      @media (max-width: 576px) {
        font-size: 1rem;
      }
    }

    p,
    li {
      color: #495057;
      font-size: 1.125rem;
      line-height: 1.6;

      @media (max-width: 576px) {
        font-size: 1rem;
      }
    }

    ul {
      list-style: disc;
      padding-left: 1.5rem;
      margin: 0;

      li {
        margin-bottom: 0.5rem;
      }
    }
  }

  .flex-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .condition {
    flex: 1 1 calc(33% - 1.5rem);
    border: 1px solid #e0e0e0;
    border-radius: 0.5rem;
    padding: 1.5rem;
    background-color: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
      flex: 1 1 100%;
    }
  }
}
</style>
