<template>
  <div class="patient-stories" id="about">
    <div class="container py-5">
      <!-- Intro Text -->
      <div
        class="intro text-center mb-5 wow animate__animated animate__fadeInUp animate__fast"
      >
        <h2>PATIENT STORIES</h2>
        <p>As Luck — and a Good Treatment Plan — Would Have It</p>
      </div>

      <!-- Patient Stories -->
      <div class="story wow animate__animated animate__fadeInUp animate__fast">
        <div class="story-text">
          <h3 class="story-title">Patient's Journey</h3>
          <p>
            A patient learned about Dr. Susan M. Ryan and the Sutter Auburn
            Faith Hospital Wound Care Clinic by chance. After suffering from a
            diabetic ulcer and facing the possibility of amputation, the patient
            sought Dr. Ryan's expertise.
          </p>
          <p>
            Dr. Ryan diagnosed the patient with osteomyelitis and recommended
            immediate surgery and stem cell therapy. The treatment included two
            surgeries, antibiotics, and regular visits to the Wound Care Clinic.
          </p>
          <p>
            "Dr. Ryan has made a huge difference in my treatment," says the
            patient. "The wound has dramatically improved. Thanks to Dr. Ryan
            and the staff at the Wound Care Clinic, I'm optimistic my foot can
            be saved."
          </p>
        </div>
        <img src="@/assets/Home/1.png" alt="Patient" class="story-img" />
      </div>
    </div>
  </div>
</template>

<script>
import { WOW } from "wowjs";
import "animate.css";

export default {
  name: "PatientStories",
  mounted() {
    new WOW().init();
  },
};
</script>

<style lang="scss" scoped>
.patient-stories {
  padding: 3rem 0;

  .intro h2 {
    color: #343a40;
    font-size: 2.5rem;
    margin-bottom: 1.5rem;

    @media (max-width: 768px) {
      font-size: 2rem;
    }

    @media (max-width: 576px) {
      font-size: 1.75rem;
    }
  }

  .intro p {
    color: #6c757d;
    font-size: 1.125rem;
    margin-bottom: 2.5rem;

    @media (max-width: 768px) {
      font-size: 1rem;
    }

    @media (max-width: 576px) {
      font-size: 0.875rem;
    }
  }

  .story {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    background: #fff;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
      flex-direction: column;
      text-align: center;
    }

    .story-text {
      flex: 1;
      margin-bottom: 1.5rem;

      @media (max-width: 768px) {
        margin-bottom: 1rem;
      }
    }

    .story-img {
      width: 100%;
      max-width: 500px; /* Adjust width as needed */
      height: auto;
      object-fit: cover;
      border-radius: 8px;

      @media (max-width: 768px) {
        max-width: 100%;
      }
    }
  }

  .story-title {
    color: #0c3c5d;
    font-size: 1.75rem;
    margin-bottom: 1rem;

    @media (max-width: 768px) {
      font-size: 1.5rem;
    }

    @media (max-width: 576px) {
      font-size: 1.25rem;
    }
  }

  .story-text {
    color: #495057;
    line-height: 1.6;
  }

  .animate__fast {
    animation-duration: 0.5s !important;
  }
}
</style>
