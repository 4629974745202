<template>
  <section class="contact-section text-dark" id="contact">
    <div class="container">
      <div class="row">
        <!-- Brand Section -->
        <div class="col-md-4 mb-4 mb-md-0">
          <h3>Contact Us</h3>
        </div>
        <!-- Contact Details -->
        <div class="col-md-8">
          <ul class="contact-list">
            <li>
              <i class="bi bi-telephone"></i>
              <a href="tel:+15102090506" class="contact-link"> +15102090506 </a>
            </li>
            <li>
              <i class="bi bi-envelope"></i>
              <a href="mailto:ryansm2013@yahoo.com" class="contact-link">
                ryansm2013@yahoo.com
              </a>
            </li>
            <li>
              <i class="bi bi-geo-alt"></i>
              <address class="contact-address">
                6000 Fairway Drive Suite 1 Rocklin, CA 95677
              </address>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ContactSection",
};
</script>

<style scoped>
.contact-section {
  padding: 60px 0;
  background-color: #fff;
  color: #333;
  border-top: 1px solid #e0e0e0;
}

h3 {
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 20px;
  color: #333;
}

.contact-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.contact-list li {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  font-size: 16px;
}

.contact-list i {
  margin-right: 15px;
  font-size: 20px;
  color: #007bff;
}

.contact-link {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s, text-shadow 0.3s;
}

.contact-link:hover {
  color: #0056b3;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.contact-address {
  color: #666;
  margin: 0;
}

@media (max-width: 767px) {
  .row {
    flex-direction: column;
  }

  .col-md-4,
  .col-md-8 {
    width: 100%;
  }

  h3 {
    font-size: 30px;
  }

  .contact-list {
    font-size: 14px;
  }
}
</style>
